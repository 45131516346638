<template>
    <div class="triangle">
        <img :src="imgSrc" alt="" />
        <div class="text">{{ text }}</div>
    </div>
</template>
<script>
import { computed } from 'vue';
export default {
    props: {
        type: Number,
    },
    name: 'Triangle',
    setup(props) {
        const imgSrc = computed(() => {
            if (props.type === 0) {
                return require('@/assets/images/triangle/success.png');
            } else if (props.type === 1) {
                return require('@/assets/images/triangle/process.png');
            } else if (props.type === 4) {
                return require('@/assets/images/triangle/finish.png');
            } else if (props.type === 3) {
                return require('@/assets/images/triangle/cancle.png');
            } else if (props.type === 2) {
                return require('@/assets/images/triangle/cancle.png');
            }
            return null;
        });
        const text = computed(() => {
            if (props.type === 0) {
                return '待批假';
            } else if (props.type === 1) {
                return '已批假';
            } else if (props.type === 4) {
                return '已完成';
            } else if (props.type === 3) {
                return '已撤销';
            } else if (props.type === 2) {
                return '已驳回';
            }
            return null;
        });
        return {
            imgSrc,
            text,
        };
    },
};
</script>
<style lang="less" scoped>
.triangle {
    position: absolute;
    top: 0;
    right: 0;
    img {
        width: 43px;
    }
    .text {
        position: absolute;
        top: -7px;
        right: -7px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg) scale(0.75);
        font-family: PingFang SC, serif;
        font-size: 12px;
        color: #ffffff;
    }
}
</style>
