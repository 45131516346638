<template>
    <div class="leave-des">
        <text-wrapper class="has-line" label="请假人">
            <div class="des">{{ leaveMsg.studentName }}</div>
        </text-wrapper>
        <text-wrapper class="has-line" label="请假类型">
            <div class="des">{{ leaveMsg.typeName }}</div>
        </text-wrapper>
        <text-wrapper class="has-line" label="请假理由">
            <div class="des">{{ leaveMsg.reason }}</div>
        </text-wrapper>
        <text-wrapper class="has-line" label="申请时间">
            <div class="des">{{ leaveMsg.addTime }}</div>
        </text-wrapper>
        <text-wrapper class="has-line" label="开始时间">
            <div class="des">{{ leaveMsg.startTime ? formatTime(leaveMsg.startTime) : '' }}</div>
        </text-wrapper>
        <text-wrapper class="has-line" label="结束时间">
            <div class="des">{{ leaveMsg.endTime ? formatTime(leaveMsg.endTime) : '' }}</div>
        </text-wrapper>
        <text-wrapper class="has-line" label="请假时长">
            <div class="des">{{ leaveMsg.duration ? parseInt(leaveMsg.duration) : '' }}天</div>
        </text-wrapper>
<!--        <text-wrapper label="请假时间">-->
<!--            <div class="des">-->
<!--                <span>{{ leaveMsg.startTime ? formatTime(leaveMsg.startTime) : '' }}</span>-->
<!--                <span class="arrive">至</span>-->
<!--                <span>{{ leaveMsg.endTime ? formatTime(leaveMsg.endTime) : '' }}</span>-->
<!--                <div class="days">-->
<!--                    <span-->
<!--                        >共<span class="day">{{ leaveMsg.duration ? parseInt(leaveMsg.duration) : '' }}</span-->
<!--                        >天</span-->
<!--                    >-->
<!--                </div>-->
<!--            </div>-->
<!--        </text-wrapper>-->
    </div>
</template>
<script>
import TextWrapper from '@/components/text/text';
import dayjs from 'dayjs';
import { DATE_TIME_FORMATE } from '@/constants';
export default {
    components: {
        [TextWrapper.name]: TextWrapper,
    },
    name: 'LeaveDes',
    props: {
        leaveMsg: {
            type: Object,
            default: () => {},
        },
    },
    setup() {
        const formatTime = (time) => {
            return dayjs(time).format(DATE_TIME_FORMATE);
        };
        return {
            formatTime,
        };
    },
};
</script>
<style lang="less" scoped>
.leave-des {
    /deep/ .label {
        font-size: 13px;
        color: #999999;
    }
    /deep/ .text-wrapper {
        margin-bottom: 0;
        border-radius: 0;
    }
    .des {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
        flex: 1;
        position: relative;
        height: 100%;
        span {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
        }
        .arrive {
            font-size: 13px;
            color: #999999;
            margin: 0 5px;
        }
        .days {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            span {
                font-size: 13px;
                font-family: PingFang SC, serif;
                font-weight: bold;
                color: #808080;
            }
            .day {
                font-size: 16px;
                color: #e64141;
                margin: 0 8px 0 4px;
            }
        }
    }

    .has-line {
        position: relative;
        &::after {
            content: '';
            left: 10px;
            right: 0;
            height: 1px;
            position: absolute;
            bottom: 0;
            background: #f2f2f2;
        }
    }
}
</style>
